export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "main-visual": {
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
    "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latin America"])},
    "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])}
  },
  "userFrontEnd": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩家前台介面"])},
    "list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专属页面设计"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多款推荐模板任你选"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["页面优化设计，提升玩家体验"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML5 + 跨装置自适应设计，多语言随时切换"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20000+ 款热门娱乐游戏，满足各类玩家需求"])}
    ],
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out the Design Template now"])}
  },
  "boi": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营运后台系统"])},
    "text": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供友善操作介面，同时具备强大功能，满足商户各种营运需求。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商户可以自行设定网站参数，包含：行销活动、支付方式、游戏交易、客製化管理文案系统（CMS）等功能。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    ],
    "list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报表中心"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩家管理"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资金管理"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏管理"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动促销管理"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后台内容编辑"])}
    ]
  },
  "advantage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["核心优势?"])},
    "content": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据中心"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["随时监控营运状况，掌握玩家动态与最新趋势。"])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据分析"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营运分析报表"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营利报表"])}
        ]
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 全天候支援"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不分时区，任何问题随时找到我们"])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技术支援"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客服支援"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        ]
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业团队支持"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顶尖专业团队，满足您从平台规划到设计运营的所有需求"])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一对一专属服务"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技术开发团队"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业设计团队"])}
        ]
      }
    ]
  },
  "gameProviders": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏供应商"])}
  },
  "payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付方式"])}
  },
  "process": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["流程说明"])},
    "content": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谘询洽谈 →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解您的需求，规划专属方案。"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约签订 →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认细节，双方签约合作。"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["风格设计 →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择模板与设计风格，量身打造您的品牌形象。"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站建置 →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认设计后，开始网站搭建。"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内容确认与修改 →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网站完成后，确认细节并进行必要调整。"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正式上线"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一切就绪，网站正式上线营运！"])}
      }
    ]
  },
  "partner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作优势"])},
    "content": {
      "yc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速回应"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持多种加密货币"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门游戏不断更新"])}
      ],
      "wc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全可靠"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简单註册，快速开玩"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多设备完美支援"])}
      ],
      "mc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优质玩家体验"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存提款快速到帐"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门优惠活动不间断"])}
      ]
    }
  },
  "googleForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联络我们"])}
  }
}
export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "main-visual": {
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Público"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alvo"])},
    "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japão"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["América Latina"])}
  },
  "userFrontEnd": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front-End do Usuário"])},
    "list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desenho de Página Personalizado"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversos Designs de Modelos Recomendados"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otimizando Páginas para Melhorar as Experiências dos Jogadores."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML 5, Design Web Responsivo,Troca Multilíngue"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20000+ Jogos Populares e Divertidos"])}
    ],
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out the Design Template now"])}
  },
  "boi": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interface de Operações em Segundo Plano"])},
    "text": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornece uma interface amigável para que os usuários operem, mas poderosa o suficiente para lidar com as necessidades dos usuários."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os usuários podem configurar todos os parâmetros para o site deles, incluindo campanhas, pagamentos, transações de jogos, CMS e mais."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    ],
    "list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro de Relatórios"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de Jogadores"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de Fundos"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de Jogos"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de Promoções"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciamento de Conteúdo"])}
    ]
  },
  "advantage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vantagem?"])},
    "content": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco de Dados"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permite monitorar o negócio e ficar atualizado com as últimas notícias dos jogadores."])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de Dados"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatório de Análise de Operações"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatório de Lucro"])}
        ]
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte 24/7"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem fuso horário entre nós quando ajuda é necessária ou problemas surgem."])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte Técnico"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atendimento ao Cliente"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        ]
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profissionais"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recrutando a melhor equipe para satisfazer suas necessidades."])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço Individualizado"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desenvolvimento de Tecnologia"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipe de Design"])}
        ]
      }
    ]
  },
  "gameProviders": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornecedores de Jogos"])}
  },
  "payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamentos"])}
  },
  "process": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processo"])},
    "content": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulte o produto e faça o seu plano. "])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura de Contrato→"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discuta mais detalhes e assine o contrato. "])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha seu modelo e designs."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construção do Site →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construiremos o site após confirmar o design."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisão →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique se algo precisa ser revisado."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicação"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de colocar seu site no ar!"])}
      }
    ]
  },
  "partner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parceiro"])},
    "content": {
      "yc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resposta Rápida"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversas Criptomoedas"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogos Mais Recentes e Populares"])}
      ],
      "wc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguro e Confiável"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simples para Registrar e Jogar"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte para Múltiplos Dispositivos"])}
      ],
      "mc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boa Experiência do Usuário"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço Rápido de Saque/Depósito"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promoções Populares"])}
      ]
    }
  },
  "googleForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contate-nos"])}
  }
}
export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "main-visual": {
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["objetivo"])},
    "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japón"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["América Latina"])}
  },
  "userFrontEnd": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front-End del Usuario"])},
    "list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño de Página Personalizado"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varios Diseños de Plantillas Recomendadas"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimización de Páginas para Mejorar las Experiencias de los Jugadores."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML 5, Diseño Web Responsivo,Cambio Multilingüe"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20000+ Juegos Populares y Divertidos"])}
    ],
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out the Design Template now"])}
  },
  "boi": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaz de Operación en Segundo Plano"])},
    "text": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporciona una interfaz amigable para que los usuarios operen, pero lo suficientemente poderosa para hacer frente a las necesidades de los usuarios."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los usuarios pueden configurar todos los parámetros para su sitio, incluyendo campañas, pagos, transacciones de juegos, CMS y más."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    ],
    "list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro de Informes"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de Jugadores"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de Fondos"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de Juegos"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de Promociones"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de Contenido"])}
    ]
  },
  "advantage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventaja"])},
    "content": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco de Dados"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permite monitorear el negocio y estar al tanto de las últimas noticias de los jugadores."])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de Datos"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe de Análisis de Operaciones"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe de Ganancias"])}
        ]
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte 24/7"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin diferencia horaria entre nosotros cuando se necesita ayuda o se enfrentan problemas."])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte Técnico"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio al Cliente"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        ]
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionales"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclutando al mejor equipo para satisfacer sus necesidades."])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio Personalizado"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desarrollo Tecnológico"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipo de Diseño"])}
        ]
      }
    ]
  },
  "gameProviders": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juegos"])}
  },
  "payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos"])}
  },
  "process": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso"])},
    "content": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta el producto y haz tu plan."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma de contrato →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discute más detalles y firma el contrato."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige tu plantilla y diseños."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construcción de sitio →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construiremos el sitio después de confirmar el diseño."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisión →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica si algo necesita ser revisado."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicación"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Es hora de poner tu sitio web en línea!"])}
      }
    ]
  },
  "partner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socio"])},
    "content": {
      "yc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta Rápida"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varias Criptomonedas"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juegos Más Recientes y Populares"])}
      ],
      "wc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguro y Confiable"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sencillo para Registrarse y Jugar"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte Multiplataforma"])}
      ],
      "mc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buena Experiencia del Usuario"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio Rápido de Retiro/Depósito"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promociones Populares"])}
      ]
    }
  },
  "googleForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctenos"])}
  }
}
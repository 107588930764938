export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "main-visual": {
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
    "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latin America"])}
  },
  "userFrontEnd": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Front-End"])},
    "list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized Page Design"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various Recommended Template Designs"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizing Pages to Improve Player Experiences"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML 5, Responsive Web Design Multilingual switch"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20000+ Popular Entertaining Games"])}
    ],
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out the Design Template now"])}
  },
  "boi": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background Operation Interface"])},
    "text": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide a friendly interface for users to operate, but powerful enough to deal with users' needs."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are able to set all the parameters for their site including campaigns, payments, game transactions, CMS, and more."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    ],
    "list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Center"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player Management"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fund Management"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Management"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions Management"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Management"])}
    ]
  },
  "advantage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advantage?"])},
    "content": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow monitoring the business and keep abreast of the latest news of players."])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Analysis"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation Analyzation Report"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit Report"])}
        ]
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 Support"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time zone between us while help is needed or facing problems."])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tech Support"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        ]
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionals"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiting the best team to satisfy your needs."])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-to-One Service"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology Development"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Team"])}
        ]
      }
    ]
  },
  "gameProviders": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Providers"])}
  },
  "payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])}
  },
  "process": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
    "content": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consult the product and make your plan."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Signing →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discuss more details and sign the contract."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your template and designs."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Building →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll build the site after confirming the design."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revision →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check if anything needs to be revised."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Live"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time to make your website go live!"])}
      }
    ]
  },
  "partner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner"])},
    "content": {
      "yc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Responsiveness"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various Cryptocurrency"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest and Popular Games"])}
      ],
      "wc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe and Reliable"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple to Register and Play"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-device Support"])}
      ],
      "mc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good User Experience"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast Withdrawal / Deposit Service"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular Promotions"])}
      ]
    }
  },
  "googleForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  }
}